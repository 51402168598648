import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '@common/auth/use-auth';

interface CartItem {
	id: string;
	type: string;
	name: string;
	originalPrice: number;
	price: number;
	image: string;
	package?: {
		name: string;
		price: string;
		stream: string;
		href: string;
	};
	metadata: any;
}

export function CartCheckoutPage() {
	const [cart, setCart] = useState<Record<string, CartItem>>({});
	const [loading, setLoading] = useState(true);
	const [processingCheckout, setProcessingCheckout] = useState(false);
	const [addOns, setAddOns] = useState<{ [key: string]: boolean }>({
		campaignUpgrade: false,
		consultationUpgrade: false,
	});

	// State for promo code and discount amount
	const [promoCode, setPromoCode] = useState('');
	const [promoDiscount, setPromoDiscount] = useState(0);
	const { user, isLoggedIn, isSubscribed } = useAuth();

	useEffect(() => {
		const fetchCart = async () => {
			try {
				const response = await axios.get('/api/cart');
				if (response.data.success) {
					setCart(response.data.cart);
				}
			} catch (error) {
				console.error('Error fetching cart:', error);
			} finally {
				setLoading(false);
			}
		};
		fetchCart();
	}, []);

	const removeFromCart = async (cartItemId: string) => {
		try {
			console.log("Removing item:", cartItemId);
			const updatedCart = { ...cart };
			delete updatedCart[cartItemId];
			setCart(updatedCart);
			await axios.post('/api/cart-remove', { cart_item_id: cartItemId });
		} catch (error) {
			console.error('Error removing from cart:', error);
			const response = await axios.get('/api/cart');
			if (response.data.success) {
				setCart(response.data.cart);
			}
		}
	};

	const addAddonToCart = async (addon: any) => {
		try {
			console.log("Adding addon:", addon.id);
			const addonItem = {
				id: `addon-${Date.now()}`,
				type: 'addon',
				name: addon.name,
				originalPrice: parseFloat(addon.originalPrice.replace('$', '')),
				price: parseFloat(addon.price.replace('$', '')),
				image: 'https://placehold.co/200x200/black/white?text=Addon',
				metadata: { description: addon.description }
			};
			const tempId = `temp-${Date.now()}`;
			setCart(prevCart => ({ ...prevCart, [tempId]: addonItem }));
			if (addon.id === 'campaign-upgrade') {
				setAddOns(prev => ({ ...prev, campaignUpgrade: true }));
			} else if (addon.id === 'consultation') {
				setAddOns(prev => ({ ...prev, consultationUpgrade: true }));
			}
			await axios.post('/api/cart-add', addonItem);
		} catch (error) {
			console.error('Error adding addon to cart:', error);
		}
	};

	const calculateSubtotal = () => {
		return Object.values(cart).reduce((sum, item) => sum + item.originalPrice, 0);
	};

	const calculateActualSubtotal = () => {
		return Object.values(cart).reduce((sum, item) => sum + item.price, 0);
	};

	const calculateDiscount = () => {
		const promotionItems = Object.values(cart).filter(item => item.type === 'promotion');
		if (promotionItems.length > 1) {
			const additionalSongsTotal = promotionItems.slice(1).reduce((sum, item) => sum + item.price, 0);
			return additionalSongsTotal * 0.1;
		}
		return 0;
	};

	const calculateTax = () => {
		return (calculateActualSubtotal() - (calculateActualSubtotal() - calculateActualSubtotal() + calculateDiscount())) * 0.2;
	}

	const calculateTotal = () => {
		return (calculateActualSubtotal() - calculateDiscount() - promoDiscount) * 1.2;
	};

	// Handler for applying a promo code
	const applyPromoCode = async () => {
		try {
			const response = await axios.post('/api/validate-promo', {
				promoCode: promoCode.trim().toUpperCase()
			});

			if (response.data > 0) {
				// Assuming the API returns the discount amount directly
				setPromoDiscount(response.data);
			} else {
				setPromoDiscount(0);
				alert('Invalid promo code');
			}
		} catch (error) {
			console.error('Error validating promo code:', error);
			setPromoDiscount(0);
			alert('Error validating promo code');
		}
	};

	// Handler for proceeding to checkout
	const handleCheckout = async () => {
		try {
			setProcessingCheckout(true);

			// Send cart data to create a checkout session
			const response = await axios.post('/api/cart-checkout-process', {
				cart,
				total: calculateTotal(),
				promoCode: promoCode.trim().toUpperCase(),
				email: user?.email || '' // Only include email if available
			});

			if (response.data.success && response.data.checkout_url) {
				// Redirect to Stripe Checkout
				window.location.href = response.data.checkout_url;
			} else {
				alert('Payment processing failed');
			}
		} catch (err) {
			console.error('Error creating checkout:', err);
			alert('There was an error processing your checkout');
		} finally {
			setProcessingCheckout(false);
		}
	};

	const availableAddons = [
		{
			id: 'campaign-upgrade',
			name: 'Priority Placement',
			description: 'Playlist Placements within 48 hours',
			originalPrice: '$69.99',
			price: '$34.99',
			discount: '50% OFF',
			features: [
				'Playlist Placements within 48 hours',
				'Stay on playlists 1 extra week',
			]
		},
		{
			id: 'consultation',
			name: 'Campaign Video consultation',
			description: '30 min video call about your campaign',
			originalPrice: '$99.99',
			price: '$49.99',
			discount: '40% OFF',
			features: [
				'30 min video call about your campaign'
			]
		},
	];

	if (loading) {
		return (
			<div className="flex justify-center items-center h-screen bg-black">
				<div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-danger"></div>
			</div>
		);
	}

	return (
		<div className="bg-black min-h-screen">
			<div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
				<h1 className="text-3xl font-bold text-white mb-10">Your Cart</h1>

				{/* Cart Items */}
				<div className="bg-gray-900 rounded-lg p-6 mb-8">
					<h2 className="text-xl font-semibold text-white mb-6">Items</h2>

					<div className="space-y-6">
						{Object.entries(cart).length === 0 ? (
							<div className="text-white text-center py-6">Your cart is empty</div>
						) : (
							Object.entries(cart).map(([cartItemId, item]) => {
								const isAdditionalPromotion =
									item.type === 'promotion' &&
									Object.values(cart).filter(i => i.type === 'promotion').findIndex(i => i === item) > 0;

								return (
									<div key={cartItemId} className="border-b border-gray-800 pb-6 last:border-0">
										<div className="flex items-center">
											<div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md">
												<img src={item.image} alt={item.name} className="h-full w-full object-cover" />
											</div>

											<div className="ml-4 flex-1">
												<div className="flex justify-between">
													<h3 className="text-lg font-medium text-white">{item.name}</h3>

													<div className="flex flex-col items-end">
														{isAdditionalPromotion ? (
															<>
																<span className="text-sm text-gray-400 line-through">${item.originalPrice.toFixed(2)}</span>
																<span className="text-md text-white">${(item.price * 0.9).toFixed(2)}</span>
															</>
														) : isSubscribed && item.type === 'track' ? (
															<>
																<span className="text-sm text-gray-400 line-through">${(item.originalPrice).toFixed(2)}</span>
																<span className="text-md text-white">${item.price.toFixed(2)}</span>
															</>
														) : item.type === 'promotion' ? (
															<>
																<span className="text-sm text-gray-400 line-through">${(item.originalPrice).toFixed(2)}</span>
																<span className="text-md text-white">${item.price.toFixed(2)}</span>
															</>
														) : item.type === 'addon' ? (
															<>
																<span className="text-sm text-gray-400 line-through">${(item.originalPrice).toFixed(2)}</span>
																<span className="text-md text-white">${item.price.toFixed(2)}</span>
															</>
														) : (
															<span className="text-md text-white">${item.price.toFixed(2)}</span>
														)}
													</div>
												</div>

												<p className="mt-1 text-sm text-gray-400">
													{item.type === 'promotion'
														? (item.package?.name || 'Promotion package')
														: (item.type === 'addon' ? item.metadata.description : 'Standard')}
												</p>

												<div className="mt-2">
													<button
														onClick={() => removeFromCart(cartItemId)}
														className="text-danger text-sm font-medium flex items-center"
													>
														<XMarkIcon className="h-4 w-4 mr-1" />
														Remove
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})
						)}
					</div>
				</div>

				{/* Promo Code */}
				<div className="bg-gray-900 rounded-lg p-6 mb-8">
					<h2 className="text-xl font-semibold text-white mb-4">Promo Code</h2>

					<div className="flex space-x-4">
						<input
							className="w-full rounded-lg bg-gray-800 p-3 text-white transition-all duration-300 ease-in-out border border-gray-700 border-opacity-75 text-md focus:ring-1 focus:ring-danger"
							placeholder="Enter promo code"
							type="text"
							value={promoCode}
							onChange={(e) => setPromoCode(e.target.value)}
						/>
						<button
							className="rounded-lg bg-danger px-4 py-3 text-white transition-all duration-200 ease-out hover:bg-dangerlighter disabled:bg-gray-700 disabled:text-white disabled:opacity-50"
							onClick={applyPromoCode}
							type="button"
						>
							Apply
						</button>
					</div>

					{promoDiscount > 0 && (
						<div className="mt-2 text-green-400 text-sm">
							Promo code applied: ${promoDiscount.toFixed(2)} discount
						</div>
					)}
				</div>

				{/* Order Summary */}
				<div className="bg-gray-900 rounded-lg p-6 mb-8">
					<h2 className="text-xl font-semibold text-white mb-6">Order Summary</h2>

					<div className="space-y-4">
						<div className="flex justify-between">
							<span className="text-gray-400">Subtotal</span>
							<span className="text-white">${calculateSubtotal().toFixed(2)}</span>
						</div>

						<div className="flex justify-between">
							<span className="text-gray-400">Discount</span>
							<span className="text-white">- ${(calculateSubtotal() - calculateActualSubtotal()).toFixed(2)}</span>
						</div>

						{calculateDiscount() > 0 && (
							<div className="flex justify-between">
								<span className="text-gray-400">10% off for additional songs</span>
								<span className="text-white">- ${calculateDiscount().toFixed(2)}</span>
							</div>
						)}

						{promoDiscount > 0 && (
							<div className="flex justify-between">
								<span className="text-gray-400">Promo discount</span>
								<span className="text-white">- ${promoDiscount.toFixed(2)}</span>
							</div>
						)}

						<div className="flex justify-between">
							<span className="text-gray-400">Estimated tax</span>
							<span className="text-white">${calculateTax().toFixed(2)}</span>
						</div>

						<div className="border-t border-gray-800 pt-4 mt-4">
							<div className="flex justify-between items-center">
								<span className="text-xl font-medium text-white">Total</span>
								<div className="text-right">
									<div className="text-sm text-danger">USD</div>
									<div className="text-2xl font-bold text-white">${calculateTotal().toFixed(2)}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Add-ons */}
				<div className="bg-gray-900 rounded-lg p-6 mb-8">
					<h2 className="text-xl font-semibold text-white mb-6">Popular add-ons 🔥</h2>

					<div className="space-y-6">
						{availableAddons.map((addon) => (
							<div key={addon.id} className="bg-gray-800 rounded-lg p-4 relative">
								<div className="flex flex-col md:flex-row md:items-center">
									<div className="flex-1">
										<h3 className="text-lg font-bold text-white">
											{addon.name} (<span className="text-danger">{addon.discount}</span>)
										</h3>

										<div className="mt-2 space-y-1">
											{addon.features.map((feature, index) => (
												<p key={index} className="text-sm text-gray-400">✅ {feature}</p>
											))}
										</div>

										<div className="mt-3 text-sm text-white">
											for only <span className="text-gray-400 line-through">{addon.originalPrice}</span> {addon.price}
										</div>
									</div>

									<div className="mt-4 md:mt-0">
										<button
											type="button"
											className="flex h-10 w-10 items-center justify-center rounded-md bg-white cursor-pointer hover:bg-gray-200 active:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
											onClick={() => addAddonToCart(addon)}
											disabled={
												(addon.id === 'campaign-upgrade' && addOns.campaignUpgrade) ||
												(addon.id === 'consultation' && addOns.consultationUpgrade)
											}
										>
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
												<path d="M5 12h14"></path>
												<path d="M12 5v14"></path>
											</svg>
										</button>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>

				{/* Checkout Button */}
				<button
					onClick={handleCheckout}
					disabled={processingCheckout || Object.keys(cart).length === 0}
					className="w-full rounded-lg bg-danger py-4 text-xl font-bold text-white transition-all ease-out hover:bg-dangerlighter disabled:opacity-50"
				>
					{processingCheckout ? 'Processing...' : 'Pay Now'}
				</button>
			</div>
		</div>
	);
}

export default CartCheckoutPage;